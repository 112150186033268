

document.addEventListener('DOMContentLoaded', function() {

  // Allow header/main nav dropdowns on desktop to open via hover
  var dropdowns = document.querySelector('header').querySelectorAll('.dropdown');

  dropdowns.forEach(dropdown => {
    var dropdownToggle = dropdown.querySelector('.dropdown-toggle');
    var dropdownMenu = dropdownToggle.nextElementSibling;
    dropdown.addEventListener('mouseenter', () => {
      dropdownToggle.classList.add('show');
      dropdownToggle.setAttribute('aria-expanded', 'true');
      dropdownMenu.classList.add('show');
    })
    dropdown.addEventListener('mouseleave', () => {
      dropdownToggle.classList.remove('show');
      dropdownToggle.setAttribute('aria-expanded', 'false');
      dropdownMenu.classList.remove('show');
    })
  });

  // Animate transition for mobile menu toggle
  if( document.getElementById('mobile-menu') ) {
    var offcanvas = document.getElementById('mobile-menu');

    offcanvas.addEventListener('show.bs.offcanvas', () => {
      document.querySelector('#mobile-menu-toggle').classList.add('is-active');
    });
    offcanvas.addEventListener('hide.bs.offcanvas', () => {
      document.querySelector('#mobile-menu-toggle').classList.remove('is-active');
    });
  }

  // Validate forms
  var forms = document.querySelectorAll('.needs-validation');

  Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      };
      form.classList.add('was-validated');
    }, false);
  });

  // Contact Us form adjustments
  document.addEventListener( 'wpcf7mailsent', function( event ) {
    var form = document.querySelector('.wpcf7');
    var message = document.querySelector('.thank-you-message');
    form.remove();
    message.style.display = 'block';
  }, false );

  // Disclaimer jump links
  const symbols = [...document.querySelectorAll('sup')];
  const disclaimers = [...document.querySelectorAll('#footer-disclaimers li')];

  let disclaimerSymbols = disclaimers.map(disclaimer => {
    return disclaimer.dataset.symbol
  });

  symbols.forEach(symbol => {
    if(disclaimerSymbols.includes(symbol.innerHTML)) {
      symbol.setAttribute('tabindex', '0');
      symbol.classList.add('disclaimer');

      const jumpToDisclaimer = (sup) => {
        let disclaimer = disclaimers.find((symbol) => symbol.dataset.symbol === sup);
        let disclaimerWrapper = disclaimer.querySelector('span');
        let returnKey = disclaimer.querySelector('.return');
        returnKey.style.display = 'inline-block';
        disclaimerWrapper.classList.add('highlighted');
        disclaimer.scrollIntoView({behavior: "instant"});
        returnKey.focus();
      }

      symbol.addEventListener('click', e => {
        jumpToDisclaimer(symbol.innerHTML);
        symbol.classList.add('current');
      })

      symbol.addEventListener('keypress', e => {
        if (e.key === 'Enter') {
          jumpToDisclaimer(symbol.innerHTML);
          symbol.classList.add('current');
        }
      })
    }
  })

  disclaimers.forEach(disclaimer => {
    let returnKey = disclaimer.querySelector('.return');

    jQuery(document).ready( function($) {
      $(disclaimer).wrapInner('<span></span>');
    });

    const jumpReturn = () => {
      let symbols = [...document.body.getElementsByTagName('sup')];
      let symbolJump = symbols.find((symbol) => symbol.classList.contains('current'));
      let disclaimerWrapper = disclaimer.querySelector('span');
      returnKey.style.display = 'none';
      disclaimerWrapper.classList.remove('highlighted');
      symbolJump.scrollIntoView({behavior: "instant"});
      symbolJump.focus();
    }

    if(returnKey) {
      returnKey.setAttribute('tabindex', '0');
      
      returnKey.addEventListener('click', e => {
        jumpReturn();
      })

      returnKey.addEventListener('keypress', e => {
        if (e.key === 'Enter') {
          jumpReturn();
        }
      })
    }
  })

  // Dynamic schema markup

  if( document.body.classList.contains('page-template-business-line-of-credit') ) {

    // Product schema
    const schema = {
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": "Business Line of Credit",
      "image": "https://s3.us-east-2.amazonaws.com/email-assets.enova.com/OnDeck/font-end-assets/plantshop_circle.png",
      "description": "With a line of credit, you can get approved for ongoing access to credit. You can draw funds as needed to use as cash. And as you repay what you’ve borrowed, you replenish your available credit. This allows you to keep funds at your fingertips — without the need to reapply. Lines of credit from $6K - $100K.",
      "brand": {
        "@type": "Brand",
        "name": "OnDeck"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": ondeck_frontend.trustpilot.numberOfReviews.total.toString(),
        "reviewCount": ondeck_frontend.trustpilot.numberOfReviews.total.toString()
      },
      "review": {
        "@type": "Review",
        "name": "Business Line of Credit- Fast, easy, generous, and professional",
        "reviewBody": "Brett was my consultant throughout this loan process and he was fabulous. This company is punctual, professional, and so easy to work with. I was approved for a business loan to expand my business and they were very generous in their offering and I’m excited for this new chapter for my business! Highly recommended. The process was fast and very easy. Even though I have my credit frozen it was still a smooth process for me and low stress. Now I can use these funds to grow my business and get one step closer to a medical retirement! Yay!",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        },
        "datePublished": "2024-06-28",
        "author": {"@type": "Person", "name": "Dee Dee"},
        "publisher": {"@type": "Organization", "name": "Trustpilot"}
      }
    };
    
    // Inject schema markup into HTML
    var script = document.createElement('script');
    script.type = 'application/ld+json';
    script.classList.add('product-schema');
    script.innerHTML = JSON.stringify(schema);
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  if( document.body.classList.contains('page-template-business-term-loan') ) {

    // Product schema
    const schema = {
      "@context": "https://schema.org/", 
      "@type": "Product",
      "name": "Business Term Loan",
      "image": "https://s3.us-east-2.amazonaws.com/enova-brand-icon-assets-prod.us-east-2/Brand+Icons/OnDeck/Features+and+Benefits+Icons/SVG/no_hard_credit_pulls.svg",
      "description": "At OnDeck, our term loans can provide fast funding for any business need. If approved, funds are delivered as soon as the same business day. Short term loans from $5,000 to $250,000, term lengths up to 12 months, and Prepayment Benefits. Apply in minutes and get Same Day Funding.",
      "brand": {
        "@type": "Brand",
        "name": "OnDeck"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "worstRating": "1",
        "ratingCount": ondeck_frontend.trustpilot.numberOfReviews.total.toString(),
        "reviewCount": ondeck_frontend.trustpilot.numberOfReviews.total.toString()
      },
      "review": {
        "@type": "Review",
        "name": "Term Loans- Easy, Reliable, Quick Access to Funds",
        "reviewBody": "First, they offer quick and convenient access to funds. On Deck has a fast online application process and can provide funding in as little as one business day, making it a great option for businesses that need capital quickly. Second, On Deck provides flexible loan options. They offer a variety of loan products, including term loans and lines of credit, with amounts ranging from $5,000 to $500,000. This flexibility allows businesses to choose the loan product that best fits their needs. Additionally, On Deck uses a technology-driven approach to underwriting, which allows them to assess the creditworthiness of a business based on a variety of factors beyond just a credit score. This can make it easier for businesses with less-than-perfect credit to qualify for a loan. Overall, On Deck is a great lending service because of its quick access to funds, flexible loan options, and technology-driven underwriting process, making it a valuable resource for businesses in need of capital.",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5",
          "bestRating": "5",
          "worstRating": "1"
        },
        "datePublished": "2024-09-06",
        "author": {"@type": "Person", "name": "David Gale"},
        "publisher": {"@type": "Organization", "name": "Trustpilot"}
      }
    };

    // Inject schema markup into HTML
    var script = document.createElement('script');
    script.type = 'application/ld+json';
    script.classList.add('product-schema');
    script.innerHTML = JSON.stringify(schema);
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  if( document.body.classList.contains('post-type-archive-resource') ) {
    if( typeof resourcePosts !== 'undefined' ) {
      // Merge post arrays from resource pages
      var posts = [].concat.apply([], [resourcePosts, smallBusinessFinancingPosts, runningABusinessPosts,insightsAndStoriesPosts]);

      // ListItem schema for resource archive page
      var schema = {
        "@context":"https://schema.org/",
        "@type":"ItemList",
        "description": "Our small business resource center features hundreds of informative articles written by industry experts. Topics range from building credit to small business insights and beyond.",
        "disambiguatingDescription": "Small Business Resource Center",
        "itemListOrder": "Descending",
        "numberOfItems": posts.length,
        "itemListElement":[]
      };

      // Map posts to schema
      posts.forEach( function(post, i) {
        var postSchema = {
          "@type":"ListItem",
          "position": i + 1,
          "item": {
            "@type": "Article",
            "url": post.url,
            "headline": post.title,
            "datePublished": post.datePublished,
            "dateModified": post.dateModified,
            "image": {
              "@type": "ImageObject",
              "url": post.image
            },
            "publisher": {
              "type": "Organization",
              "name": "OnDeck",
              "url": "https://www.ondeck.com/"
            },
            "author": {
              "@type": "Person",
              "name": post.author,
              "url": post.authorURL
            }
          }
        }
        schema.itemListElement.push(postSchema);
      });
      
      // Inject schema markup into HTML
      var script = document.createElement('script');
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify(schema);
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  // FAQ component schema markup
  const hasFAQ = document.querySelector('.faq-schema');
  if( hasFAQ ) {
    var faqs = document.querySelectorAll('.faq-schema-item');
    var schema = {
      "@context":"https://schema.org/",
      "@type":"FAQPage",
      "mainEntity":[]
    };

    faqs.forEach( function(faq, i) {
      var question = faq.querySelector('.faq-schema-question').innerText;
      var answer = faq.querySelector('.faq-schema-answer').innerText;

      var faqSchema = {
        "@type": "Question",
        "name": question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer
        }
      }
      schema.mainEntity.push(faqSchema);
    });

    // Inject schema markup into HTML
    var script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(schema);
    document.getElementsByTagName('head')[0].appendChild(script);
  }
});

jQuery(document).ready( function($) {
  // Reset disclaimer jumps
  $.fn.isInViewport = function() {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(window).on('resize scroll', function() {
    if (!$('footer').isInViewport()) {
      $('.disclaimer.return').css('display', 'none');
      $('sup').removeClass('current');
      $('li span').removeClass('highlighted');
    }
  });

  // Osano
  $('.cookie-preferences a').on('click', function() {
    Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')}
  );
  
  // Sliders
  if( $('body').hasClass('page-template-small-business-loans-alt') ||
      $('body').hasClass('page-template-loans-temporary') ||
      $('body').hasClass('page-template-loans-contractors') ||
      $('body').hasClass('page-template-loans-cleaning') ||
      $('body').hasClass('page-template-loans-retail') ||
      $('body').hasClass('page-template-policy-security') ) {

    // Slick slider init
    var options = {
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: true,
      customPaging: function (slick, index) {
        return '<button type="button" data-slick-index="' + index + '">' +
          '<span class="visually-hidden">Slide ' +
          ( index + 1 ) +
          '</span></button>';
      },
      arrows: false,
      infinite: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    };

    $('.slider').slick(options);
  }

  if( $('body').hasClass('page-template-business-line-of-credit') ||
      $('body').hasClass('page-template-business-term-loan') ||
      $('body').hasClass('page-template-campaign-direct') ) {

    // Slick slider init
    var options = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      customPaging: function (slick, index) {
        return '<button type="button" data-slick-index="' + index + '">' +
          '<span class="visually-hidden">Slide ' +
          ( index + 1 ) +
          '</span></button>';
      },
      nextArrow: '<button type="button" class="slick-arrow slick-next btn btn-outline-light">' + 
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15.5" class="arrow"><path d="M7.7 8.5c.4-.4.4-1.1 0-1.6L1.5.3C1.3.1 1.1 0 .9 0 .4 0 0 .3 0 .8c0 .2.1.5.2.6l5.9 6.3L.2 14c-.3.3-.3.9 0 1.2.2.1.4.2.6.2.2 0 .4-.1.6-.3.1.1 6.3-6.6 6.3-6.6z"/></svg>' +
                  '<span class="visually-hidden">Next Slide</span>' +
                '</button>',
      prevArrow: '<button type="button" class="slick-arrow slick-prev btn btn-outline-light">' + 
                  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15.5" class="arrow"><path d="M.3 6.9c-.4.4-.4 1.1 0 1.6l6.2 6.6c.2.2.4.3.6.3.5 0 .9-.3.9-.8 0-.2-.1-.5-.2-.6L1.9 7.7l5.9-6.3c.3-.3.3-.9 0-1.2-.2-.1-.4-.2-.6-.2-.2 0-.4.1-.6.3C6.5.2.3 6.9.3 6.9z"/></svg>' +
                  '<span class="visually-hidden">Previous Slide</span>' +
                '</button>',
      arrows: true,
      infinite: false,
      responsive: [{
        breakpoint: 992,
        settings: {
          arrows: false
        }
      }]
    };

    $('.slider').slick(options);
  }

  // FAQ page search function
  if( $('body').hasClass('page-template-faqs') ) {
    $(document).on('keyup search', '#faq-search', function() {
      var e = $(this).val().replace(/[^\w]/g, '').toLowerCase();
      e ? ($('.accordion').addClass('faq-search-active'), $('.accordion-item').removeClass('faq-search-match').filter(function() {
        var a = $(this).data('faq-plaintext');
        return a || (a = $(this).text().replace(/[^\w]/g, '').toLowerCase(), $(this).data('faq-plaintext', a)), a.match(e)
      }).addClass('faq-search-match')) : ($('.accordion').removeClass('faq-search-active'), $('.accordion-item.faq-search-match').removeClass('.faq-search-match'))
    })
  }

  // Contact Us page formatting fields to use floating labels
  if( $('body').hasClass('page-template-contact-us') ) {
    $('.wpcf7-form-control-wrap').each(function() {
      var label = $(this).next('label').detach();
      var field = $(this).find('.form-control');
      $(this).append(label).addClass('form-floating');
      if(field) {
        field.val('');
      }
    })
  }

  // News page AJAX functions
  if( $('body').hasClass('page-template-news') ) {
    function news_load_all_posts(page) {
      var data = {
        page: page,
        postType: 'post',
        action: 'pagination_load_posts'
      };

      $.post(ondeck_frontend.ajaxurl, data, function(response) {
        $('.news-container').html(response);
      });
    }

    function pr_load_all_posts(page) {
      var data = {
        page: page,
        postType: 'press_release',
        action: 'pagination_load_posts'
      };

      $.post(ondeck_frontend.ajaxurl, data, function(response) {
        $('.pr-container').html(response);
      });
    }

    news_load_all_posts(1);
    pr_load_all_posts(1);

    // Pagination
    $('.news-container').on('click', '.news-pagination a', function(event) {
      event.preventDefault();
      var page = $(this).attr('data-page');
      news_load_all_posts(page);
      $('html, body').animate({scrollTop: $('#news').offset().top}, 0);
    });

    $('.pr-container').on('click', '.pr-pagination a', function(event) {
      event.preventDefault();
      var page = $(this).attr('data-page');
      pr_load_all_posts(page);
      $('html, body').animate({scrollTop: $('#pr').offset().top}, 0);
    });
  }

  // Resource author page AJAX function
  if( $('body').hasClass('author') ) {
    // var author_array = window.location.pathname.split('/');
    // var author = author_array[3];

    // function resource_load_all_posts(page, author) {
    //   var data = {
    //     page: page,
    //     authorName: author,
    //     postType: 'resource',
    //     action: 'pagination_load_posts'
    //   };

    //   $.post(ondeck_frontend.ajaxurl, data, function(response) {
    //     $('.resource-container').html(response);
    //   });
    // }

    // resource_load_all_posts(1, author);

    // Pagination
    if (window.location.href.indexOf('/page/') > -1) {
      $('html, body').animate({scrollTop: $('#articles').offset().top}, 0);
    }
    // $('.author-articles').on('click', '.author-pagination a', function(event) {
      // event.preventDefault();
      // var page = $(this).attr('data-page');
      // var author = $(this).attr('data-author');
      // resource_load_all_posts(page, author);
    // });
  }

  // Limit hero lead form revenue field to digits only, to allow for pattern error validation
  if( $('body').hasClass('page-template-campaign-aadom') ||
  $('body').hasClass('page-template-campaign-affiliate') ||
  $('body').hasClass('page-template-campaign-direct') ||
  $('body').hasClass('page-template-campaign-mgma') || 
  $('body').hasClass('page-template-campaign-partner') ||
  $('body').hasClass('page-template-campaign-sem') ) {
    $('#revenue').on('input', function(e) {
      $(this).val($(this).val().replace(/[^0-9]/gi, ''));
    });
  }
  // Lead Form Query String Population
  if ($('body').hasClass('page-template-campaign-aadom') || $('body').hasClass('page-template-campaign-affiliate') || $('body').hasClass('page-template-campaign-direct') || $('body').hasClass('page-template-campaign-mgma') || $('body').hasClass('page-template-campaign-partner') || $('body').hasClass('page-template-campaign-sem')) {
    function getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");

      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");

        if (pair[0] == variable) {
          return pair[1];
        }
      }

      return "";
    }

    if (window.location.search) {
      document.querySelector('#first-name').value = getQueryVariable('firstName');
      document.querySelector('#last-name').value = getQueryVariable('lastName');
      document.querySelector('#email').value = getQueryVariable('emailAddress');
      document.querySelector('#phone').value = getQueryVariable('phoneNumber');
      document.querySelector('#revenue').value = getQueryVariable('annualRevenue');
    }
  }

  // Rewrite WP Generated Tables (resources)
  let tables = [...document.querySelectorAll(".resource-content table")];

  if( !tables ) {
    //console.log("no tables")
  } else {
    tables.forEach((table) => {
      let headers, 
          headerrow, 
          cols, 
          wrapper;

      //Create Table Head & Move first row to it
      table.prepend(document.createElement('thead'));
      headerrow = table.querySelector('tr:first-of-type');
      wrapper = table.querySelector('thead');
      wrapper.appendChild(headerrow);

      //Rewrite TDs to THs
      [...table.querySelectorAll('thead tr:first-of-type>td')].forEach(
        td => td.outerHTML = td.outerHTML
        .replace(/<td([^>]*)>/, '<th$1>')
        .replace(/<\/td>/, '</th>')
      );

      //Wrap text in tbody tds with div for styling
      [...table.querySelectorAll('tbody tr>td')].forEach(
        td => td.outerHTML = td.outerHTML
        .replace(/<td([^>]*)>/, '<td$1><div>')
        .replace(/<\/td>/, '</div></td>')
      );

      //Get table Headers
      headers = [...table.querySelectorAll('thead th')];

      //Assign Headers per Col
      headers.forEach((header, index) => {
        cols = [...table.querySelectorAll('tbody tr td:nth-of-type(' + ( index + 1 ) + ')')];
        cols.forEach((col, index) => {
          col.dataset.label = header.innerText;
        });
      });
    });
  }

  // Orcolus dropdown navigation
  if( $('body').hasClass('page-template-ocrolus') ) {
    // An alternative to Bootstrap's buggy scrollspy

    $('html').css('scroll-padding-top', '12rem');
    $(window).bind( 'scroll', function() {
      var currentTop = $(window).scrollTop();
      var elems = $('.scrollspy');

      elems.each( function(i) {
        var elemTop = $(this).offset().top;
        var elemBottom = elemTop + $(this).height();
        if( currentTop >= (elemTop - 230) && currentTop <= elemBottom ) {
          var id 		= $(this).attr('id');
          var navElem = $('a[href="#' + id+ '"]');
          var navText = $('a[href="#' + id+ '"]').attr('data-report');
          navElem.addClass('active')
          navElem.parent().siblings().find('a').removeClass( 'active' );
          $('.active-report').text(navText);
        }
      })
    });

    // Charts

    Chart.defaults.color = '#333';
    Chart.defaults.font.family = 'Poppins';
    Chart.defaults.font.size = '18';
    Chart.defaults.font.weight = '600';

    Chart.defaults.borderColor = '#ddd';

    Chart.defaults.elements.point.backgroundColor = '#0064dd';
    Chart.defaults.elements.point.radius = 5;
    
    Chart.defaults.datasets.bar.barPercentage = 1;
    Chart.defaults.datasets.bar.categoryPercentage = 0.6;

    Chart.defaults.animation = false;
    Chart.defaults.responsive = true;
    Chart.defaults.maintainAspectRatio = false;

    Chart.defaults.scale.ticks.padding = 16;

    Chart.defaults.scale.grid.lineWidth = 3;
    Chart.defaults.scale.grid.color = '#ddd';
    Chart.defaults.scale.grid.tickColor = '#ddd';

    Chart.defaults.plugins.legend.position = 'bottom';
    Chart.defaults.plugins.legend.labels.boxHeight = 12;
    Chart.defaults.plugins.legend.labels.boxWidth = 12;
    Chart.defaults.plugins.legend.labels.padding = 32;

    Chart.defaults.plugins.tooltip.bodyFont = { weight: '400', size: '12' };
    Chart.defaults.plugins.tooltip.titleFont = { size: '12' };
    Chart.defaults.plugins.tooltip.titleAlign = 'center';
    Chart.defaults.plugins.tooltip.bodyAlign = 'center';
    Chart.defaults.plugins.tooltip.padding = 16;
    Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(51,51,51,0.9)';
    Chart.defaults.plugins.tooltip.displayColors = false;
    Chart.defaults.plugins.tooltip.xAlign = 'center';
    Chart.defaults.plugins.tooltip.yAlign = 'bottom';

    console.log(Chart.defaults);

    // Q1 2024

    const chart2024Q1Median = document.getElementById('2024-q1-median');

    new Chart(chart2024Q1Median, {
      type: 'line',
      data: {
        labels: ['Apr \'23', 'May \'23', 'Jun \'23', 'Jul \'23', 'Aug \'23', 'Sep \'23', 'Oct \'23', 'Nov \'23', 'Dec \'23', 'Jan \'24', 'Feb \'24', 'Mar \'24'],
        datasets: [{
          label: 'Median Revenue by Month',
          data: ['50985', '57015', '55967', '54511', '59658.5', '56485', '62358', '59363.5', '58412', '56592', '53496', '50651.5'],
          borderColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400' },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Revenue',
            },
            ticks: {
              stepSize: 5000,
              callback: function(value, index, ticks) {
                return '$' + Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]);
              }
            },
            min: 45000,
            max: 65000,
          }
        }
      }
    });

    const chart2024Q1PayToRev = document.getElementById('2024-q1-paytorev');

    new Chart(chart2024Q1PayToRev, {
      type: 'line',
      data: {
        labels: ['Apr \'23', 'May \'23', 'Jun \'23', 'Jul \'23', 'Aug \'23', 'Sep \'23', 'Oct \'23', 'Nov \'23', 'Dec \'23', 'Jan \'24', 'Feb \'24', 'Mar \'24'],
        datasets: [{
          label: 'Payroll-to-Revenue Ratio',
          data: ['18.70', '17.34', '19.42', '17.50', '18.56', '19.44', '17.74', '20.10', '20.50', '18.25', '20.18', '19.93'],
          borderColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400', },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Ratio',
            },
            ticks: {
              stepSize: 2,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            min: 16,
            max: 22,
          }
        }
      }
    });

    const chart2024Q1FutureGrowth = document.getElementById('2024-q1-futuregrowth');

    new Chart(chart2024Q1FutureGrowth, {
      type: 'bar',
      data: {
        labels: ['Q4 2023', 'Q4 2024'],
        datasets: [{
          label: 'Significant Growth',
          data: ['25.8', '30.8'],
          backgroundColor: '#0064dd',
          borderColor: '#0064dd',
        },
        {
          label: 'Moderate Growth',
          data: ['65.8', '61.8'],
          backgroundColor: '#000082',
          borderColor: '#000082',
        },
        {
          label: 'No Growth',
          data: ['8.4', '7.3'],
          backgroundColor: '#83dcc1',
          borderColor: '#83dcc1',
        }]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            grid: { display: false },
            ticks: {
              font: { weight: '400' },
            },
          },
          y: {
            border: { display: false },
            ticks: {
              stepSize: 10,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            max: 70,
          }
        }
      }
    });

    const chart2024Q1RevToExp = document.getElementById('2024-q1-revtoexp');

    new Chart(chart2024Q1RevToExp, {
      type: 'line',
      data: {
        labels: ['Apr \'23', 'May \'23', 'Jun \'23', 'Jul \'23', 'Aug \'23', 'Sep \'23', 'Oct \'23', 'Nov \'23', 'Dec \'23', 'Jan \'24', 'Feb \'24', 'Mar \'24'],
        datasets: [{
          label: 'Boston only',
          data: ['100.73', '102.24', '101.51', '101.67', '99.76', '99.60', '100.85', '99.77', '99.75', '98.94', '100.12', '100.81'],
          borderColor: '#83dcc1',
          backgroundColor: '#83dcc1',
          pointBackgroundColor: '#83dcc1',
        },
        {
          label: 'Dallas only',
          data: ['99.39', '100.44', '99.92', '99.56', '99.41', '99.13', '100.00', '99.71', '99.76', '100.06', '100.34', '99.59'],
          borderColor: '#000082',
          backgroundColor: '#000082',
          pointBackgroundColor: '#000082',
        },
        {
          label: 'U.S.',
          data: ['99.73', '100.78', '100.03', '100.18', '99.77', '99.34', '100.47', '99.64', '99.81', '100.00', '100.23', '100.19'],
          borderColor: '#0064dd',
          backgroundColor: '#0064dd',
          pointBackgroundColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { reverse: true },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label.replace(/ only/g, '')|| '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400' },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Ratio',
            },
            ticks: {
              stepSize: 2,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            min: 95,
            max: 105,
          }
        }
      }
    });

    // Q4 2023

    const chart2023Q4Revenue = document.getElementById('2023-q4-revenue');

    new Chart(chart2023Q4Revenue, {
      type: 'line',
      data: {
        labels: ['Jul \'22', 'Aug \'22', 'Sep \'22', 'Oct \'22', 'Nov \'22', 'Dec \'22', 'Jan \'23', 'Feb \'23', 'Mar \'23', 'Apr \'23', 'May \'23', 'Jun \'23', 'Jul \'23', 'Aug \'23', 'Sep \'23', 'Oct \'23', 'Nov \'23', 'Dec \'23'],
        datasets: [{
          label: 'Median Revenue',
          data: ['41200', '47100', '46200', '48318', '49195', '53390', '54239', '50769', '56681.5', '49842', '55721', '55063.5', '54160', '59440', '55939', '61193', '56829', '55566'],
          borderColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400' },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Median Revenue',
            },
            ticks: {
              stepSize: 5000,
              callback: function(value, index, ticks) {
                return '$' + Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]);
              }
            },
            min: 40000,
            max: 65000,
          }
        }
      }
    });

    const chart2023Q4RevToExp = document.getElementById('2023-q4-revtoexp');

    new Chart(chart2023Q4RevToExp, {
      type: 'line',
      data: {
        labels: ['Jul \'22', 'Aug \'22', 'Sep \'22', 'Oct \'22', 'Nov \'22', 'Dec \'22', 'Jan \'23', 'Feb \'23', 'Mar \'23', 'Apr \'23', 'May \'23', 'Jun \'23', 'Jul \'23', 'Aug \'23', 'Sep \'23', 'Oct \'23', 'Nov \'23', 'Dec \'23'],
        datasets: [{
          label: 'Revenue-to-Expense Ratio',
          data: ['99.23', '99.72', '99.53', '99.94', '99.14', '99.55', '99.95', '100.52', '100.86', '100.13', '101.18', '100.87', '101.15', '100.73', '100.22', '100.93', '99.40', '99.10'],
          borderColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400', },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Ratio',
            },
            ticks: {
              stepSize: 2,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            min: 95,
            max: 105,
          }
        }
      }
    });

    const chart2023Q4PayToRev = document.getElementById('2023-q4-paytorev');

    new Chart(chart2023Q4PayToRev, {
      type: 'line',
      data: {
        labels: ['Jul \'22', 'Aug \'22', 'Sep \'22', 'Oct \'22', 'Nov \'22', 'Dec \'22', 'Jan \'23', 'Feb \'23', 'Mar \'23', 'Apr \'23', 'May \'23', 'Jun \'23', 'Jul \'23', 'Aug \'23', 'Sep \'23', 'Oct \'23', 'Nov \'23', 'Dec \'23'],
        datasets: [{
          label: 'Payroll-to-Revenue Ratio',
          data: ['18.57', '16.59', '19.42', '17.23', '18.46', '20.24', '17.51', '19.30', '18.89', '18.72', '17.31', '19.21', '17.21', '18.11', '19.07', '17.58', '20.27', '20.50'],
          borderColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400', },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Ratio',
            },
            ticks: {
              stepSize: 2,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            min: 16,
            max: 22,
          }
        }
      }
    });

    // Q2 2024

    const chart2024Q2FutureGrowth = document.getElementById('2024-q2-futuregrowth');

    new Chart(chart2024Q2FutureGrowth, {
      type: 'bar',
      data: {
        labels: ['Q4 2023', 'Q1 2024', 'Q2 2024'],
        datasets: [{
          label: 'Significant Growth',
          data: ['25.8', '30.8', '29.3'],
          backgroundColor: '#0064dd',
          borderColor: '#0064dd',
        },
        {
          label: 'Moderate Growth',
          data: ['65.8', '61.8', '62.2'],
          backgroundColor: '#000082',
          borderColor: '#000082',
        },
        {
          label: 'No Growth',
          data: ['8.4', '7.3', '8.5'],
          backgroundColor: '#83dcc1',
          borderColor: '#83dcc1',
        }]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            grid: { display: false },
            ticks: {
              font: { weight: '400' },
            },
          },
          y: {
            border: { display: false },
            ticks: {
              stepSize: 10,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            max: 70,
          }
        }
      }
    });

    const chart2024Q2Median = document.getElementById('2024-q2-median');

    new Chart(chart2024Q2Median, {
      type: 'line',
      data: {
        labels: ['Jun\'23', 'Jul\'23', 'Aug\'23', 'Sep\'23', 'Oct\'23', 'Nov\'23', 'Dec\'23', 'Jan\'24', 'Feb\'24', 'Mar\'24', 'Apr\'24', 'May\'24', 'Jun\'24'],
        datasets: [{
          label: 'Median Revenue by Month',
          data: ['56000', '54490', '59440', '56517', '62506', '59703', '59005', '57037', '54061', '53529', '55500', '54480', '50910'],
          borderColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400' },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Revenue',
            },
            ticks: {
              stepSize: 10000,
              callback: function(value, index, ticks) {
                return '$' + Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]);
              }
            },
            min: 35000,
            max: 75000,
          }
        }
      }
    });

    const chart2024Q2RevToExp = document.getElementById('2024-q2-revtoexp');

    new Chart(chart2024Q2RevToExp, {
      type: 'line',
      data: {
        labels: ['Jun\'23', 'Jul\'23', 'Aug\'23', 'Sep\'23', 'Oct\'23', 'Nov\'23', 'Dec\'23', 'Jan\'24', 'Feb\'24', 'Mar\'24', 'Apr\'24', 'May\'24', 'Jun\'24'],
        datasets: [{
          label: 'Cleveland only',
          data: ['98.18', '98.31', '98.34', '99.54', '99.56', '98.84', '99.34', '100.06', '100.40', '100.00', '100.25', '99.98', '99.06'],
          borderColor: '#83dcc1',
          backgroundColor: '#83dcc1',
          pointBackgroundColor: '#83dcc1',
        },
        {
          label: 'Boston only',
          data: ['101.49', '101.58', '99.81', '99.62', '100.88', '99.89', '99.95', '99.66', '100.13', '101.46', '100.63', '100.27', '101.30'],
          borderColor: '#000082',
          backgroundColor: '#000082',
          pointBackgroundColor: '#000082',
        },
        {
          label: 'U.S.',
          data: ['100.03', '100.17', '99.78', '99.35', '100.41', '99.58', '99.88', '100.27', '100.85', '101.10', '100.93', '100.29', '99.97'],
          borderColor: '#0064dd',
          backgroundColor: '#0064dd',
          pointBackgroundColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { reverse: true },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label.replace(/ only/g, '') || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400' },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Ratio',
            },
            ticks: {
              stepSize: 2,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            min: 95,
            max: 105,
          }
        }
      }
    });

    const chart2024Q2PayToRev = document.getElementById('2024-q2-paytorev');

    new Chart(chart2024Q2PayToRev, {
      type: 'line',
      data: {
        labels: ['Jun\'23', 'Jul\'23', 'Aug\'23', 'Sep\'23', 'Oct\'23', 'Nov\'23', 'Dec\'23', 'Jan\'24', 'Feb\'24', 'Mar\'24', 'Apr\'24', 'May\'24', 'Jun\'24'],
        datasets: [{
          label: 'Philadelphia only',
          data: ['21.04', '18.80', '19.61', '19.05', '17.42', '21.56', '22.60', '18.74', '20.80', '19.14', '17.73', '20.82', '18.71'],
          borderColor: '#83dcc1',
          backgroundColor: '#83dcc1',
          pointBackgroundColor: '#83dcc1',
        },
        {
          label: 'Boston only',
          data: ['18.78', '18.17', '19.38', '20.72', '17.11', '21.33', '20.40', '18.65', '21.07', '20.57', '17.68', '20.71', '20.16'],
          borderColor: '#000082',
          backgroundColor: '#000082',
          pointBackgroundColor: '#000082',
        },
        {
          label: 'U.S.',
          data: ['19.35', '17.47', '18.52', '19.35', '17.71', '20.09', '20.29', '17.93', '19.79', '19.21', '17.60', '19.59', '18.63'],
          borderColor: '#0064dd',
          backgroundColor: '#0064dd',
          pointBackgroundColor: '#0064dd',
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { reverse: true },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label.replace(/ only/g, '') || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400' },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Ratio',
            },
            ticks: {
              stepSize: 2,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            min: 14,
            max: 24,
          }
        }
      }
    });

    const chart2024Q2AltPay = document.getElementById('2024-q2-altpay');

    new Chart(chart2024Q2AltPay, {
      type: 'line',
      data: {
        labels: ['Jun\'23', 'Jul\'23', 'Aug\'23', 'Sep\'23', 'Oct\'23', 'Nov\'23', 'Dec\'23', 'Jan\'24', 'Feb\'24', 'Mar\'24', 'Apr\'24', 'May\'24', 'Jun\'24'],
        datasets: [{
          label: 'Alternative Payments Accepted',
          data: ['24.83', '24.04', '24.19', '23.58', '23.54', '23.16', '23.46', '24.72', '25.46', '26.59', '27.83', '28.29', '28.32'],
          borderColor: '#0064dd',
          pointBackgroundColor: '#0064dd',
        }]
      },
      options: {
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + '%';
                }
                return label;
              }
            }
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months',
            },
            grid: {
              color: '#fff',
              tickLength: -6,
            },
            ticks: {
              font: { weight: '400' },
              maxRotation: 45,
              minRotation: 45
            },
          },
          y: {
            border: { display: false },
            title: {
              display: true,
              text: 'Percentage',
            },
            ticks: {
              stepSize: 2,
              callback: function(value, index, ticks) {
                return Chart.Ticks.formatters.numeric.apply(this, [value, index, ticks]) + '%';
              }
            },
            min: 20,
            max: 30,
          }
        }
      }
    });
  }
});
